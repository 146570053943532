<template>
  <v-row align="center" justify="center" class="error-wrapper">
    <v-card outlined tile>
      <v-card-text>
        <div class="error-box" width="400px">
          <div class="text-center">
            <h1 class="error-title error--text">404</h1>
            <h3 class="text-uppercase error-subtitle mb-4">PAGE NOT FOUND !</h3>
            <v-btn
              v-if="!isAuthenticated"
              text
              color="info"
              href="/login"
              data-testid="btn-login-page"
            >
              <v-icon>mdi-open-in-new</v-icon>
              Login page
            </v-btn>
            <v-btn
              v-if="!isAuthenticated"
              text
              color="info"
              :href="supportLink"
              data-testid="btn-support-portal"
            >
              <v-icon>mdi-open-in-new</v-icon>
              Support potral
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  data: () => ({}),
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      supportLink: "supportLink",
    }),
  },
}
</script>
<style lang="scss">
.error-wrapper {
  height: 100%;
  min-height: calc(100vh - 120px);
}
.error-title {
  font-size: 160px;
  font-weight: 900;
  text-shadow: 2px 2px 0 #fff, 3px 3px 0 #343a40;
  line-height: 210px;
}

@media (max-width: 991px) {
  .error-title {
    font-size: 120px;
    line-height: 120px;
  }
}

@media (max-width: 767px) {
  .error-title {
    font-size: 40px;
    line-height: 40px;
  }
}
</style>
